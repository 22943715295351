import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Servicenow = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Servicenow"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">An effective Service Integration includes the provision of Integrated ITSM tooling providing interface, data policies and standards that facilitate all Service Provider feeds into a central data hub, with full communications exchange to manage the end-to-end service in real time. IT Workflows have been at the core of ServiceNow’s offerings since its inception and with just some tweaks it has the capability to become the single point for the delivery of integrated services, adapt to your business scenarios and help achieve improved ability to govern, assure and deliver IT services. However, ServiceNow has evolved much beyond IT and is now the platform of choice to simplify, clarify and streamline the support processes across any business domain, be it IT, HR, Finance, Customer support, Field Services, etc. across all industries.</p>
                        <p className="mt-3">
                        TEQT is an Elite partner to ServiceNow, with proven capabilities to provide a broad range of workflow solutions from IT and employee experience transformation to modern, digital customer experiences that revolutionize their enterprises and the way they serve their own customers. With our deep technical expertise along with domain leadership, we are best placed to co-create best-in-class solutions for our clients to accelerate their digital transformation journey on the ServiceNow platform.
                        </p>
                        <p className="mt-3">TEQT has a dedicated ServiceNow CoE in-place with proven track record of successful implementations and management for its customers. We are 200+ ServiceNow consultants with an experience of 50+ implementations across Fortune 500 customers supporting 65,000+ fulfiller licenses with over 1 million configuration items/assets in complex environments comprising of multiple integrations.</p>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query ServicenowPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Servicenow.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Servicenow;
